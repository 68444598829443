import React, { useEffect, useState } from 'react'
import Layout from "./global/Layout"
import { Label, Button, Modal, Alert, Timeline } from "flowbite-react";
import timeGridPlugin from '@fullcalendar/timegrid'
import daygridPlugin from "@fullcalendar/daygrid"
import FullCalendar from '@fullcalendar/react'
import frLocale from '@fullcalendar/core/locales/fr'
import { useNavigate } from 'react-router-dom'
import FilAriane from "../../components/FilAriane"
import AvailModal from "./components/AvailModal"
import ListAvailModal from "./components/ListAvailModal"
import { HiOutlinePlus } from "react-icons/hi";
import { parseSqlDate, getDaysArray, todayMidnight } from '../../utils/utils';
import { useCookies } from 'react-cookie'


function FormPlanning() {
    const [options, setOptions] = useState([]);
    const [unavails, setUnavails] = useState([]);
    const [avails, setAvails] = useState([]);
    const [unavailModal, setUnavailModal] = useState(false);
    const [availModal, setAvailModal] = useState(false);
    const [listAvailModal, setListAvailModal] = useState(false);
    const [eventModal, setEventModal] = useState(false);
    const [fullDayDate, setFullDayDate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [currEvent, setCurrEvent] = useState([]);
    const navigate = useNavigate()
    const [cookies] = useCookies(['userInfo'])
    var calendarInitial
    window.innerWidth > 768 ? calendarInitial = "timeGridWeek" : calendarInitial = "timeGridDay"


    function closeAvailModal () {
        setAvailModal(false)
    }
    function closeListAvailModal () {
        setListAvailModal(false)
    }

    const getUnavails = async () => {
        const response = await fetch('https://api.planileo.fr/planning/getUnavailabilities/' + cookies.userInfo.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            var results = []
            const result = await response.json();
            for (const unavail of result.unavails) {
                results.push({ title: 'Indisponibilité', start: unavail.startdate, end: unavail.enddate, extendedProps: { id: unavail.id, type: "Indisponibilité", title: "Indisponibilité" }, backgroundColor: "#727272", borderColor: "#727272" })
            }
            setUnavails(results);
        }
    };
    const getAvails = async () => {
        const response = await fetch('https://api.planileo.fr/planning/getAvailabilities/' + cookies.userInfo.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const results = await response.json();
            setAvails(results.avails)
            for (const avail of results.avails) {
                const daylist = getDaysArray(new Date(avail.startdate),new Date(avail.enddate));
                for (const day of daylist) {
                    const elem = document.querySelectorAll('.fc-timegrid-cols td.fc-timegrid-col[data-date="'+ day +'"]')
                    if( elem.length > 0 ) {
                        if( avail.weekends == 0 ) { // exclude weekends
                            if( !elem[0].classList.contains('fc-day-sat') && !elem[0].classList.contains('fc-day-sun') ) {
                                elem[0].classList.add('bg-green-400')
                            }
                        } else {
                            elem[0].classList.add('bg-green-400')
                        }
                    }
                }
            }
        }
    };
    const getOptions = async () => {
        const response = await fetch('https://api.planileo.fr/planning/getOptions/' + cookies.userInfo.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            var results = []
            const result = await response.json();
            for (const option of result.options) {
                if (option.etat === 1) { //option validée formateur
                    results.push({ title: 'Option | ' + option.entreprise, start: option.startdate, end: option.enddate, extendedProps: { type: "option", title: "Formation", status: 'valid_org', data: option }, backgroundColor: "#F8D21B", borderColor: "#F8D21B" })
                } else if (option.etat === 0) { //option posée
                    results.push({ title: 'Option | ' + option.entreprise, start: option.startdate, end: option.enddate, extendedProps: { type: "option", title: "Option", status: 'valid_form', data: option }, backgroundColor: "#F87F1B", borderColor: "#F87F1B" })
                } else { // option validée par l'organisme
                    results.push({ title: 'Formation | ' + option.entreprise, start: option.startdate, end: option.enddate, extendedProps: { type: "option", title: "Option", status: 'confirmed', data: option }, backgroundColor: "#88CA0B", borderColor: "#88CA0B" })
                }
            }
            setOptions(results);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            getUnavails();
            getOptions();
            getAvails();
        }
        fetchData()
    }, []);

    const addUnavail = async (e) => {
        e.preventDefault();
        if( startDate && !endDate ) {
            alert("Veuillez entrer une date de fin")
            return
        } 
        if( new Date(startDate) > new Date(endDate) ) {
            alert("La date de début ne peut pas être après la date de fin")
            return
        } 
        const response = await fetch('https://api.planileo.fr/planning/addUnavailability', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fullDay: fullDayDate,
                startDate: startDate,
                endDate: endDate
            }),
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                getUnavails()
                setUnavailModal(false)
                // reset
                setFullDayDate("")
                setStartDate("")
                setEndDate("")
            } else {
                alert(result.message)
            }
        }
    }

    const eventClick = (el) => {
        setEventModal(true)
        setCurrEvent(el.event._def.extendedProps)
    };

    function dayCellDidMount(day) {
        if( avails.length > 0) {
            for (const avail of avails) {
                const daylist = getDaysArray(new Date(avail.startdate),new Date(avail.enddate));
                for (const day of daylist) {
                    const elem = document.querySelectorAll('.fc-timegrid-cols td.fc-timegrid-col[data-date="'+ day +'"]:not(.bg-green-400)')
                    if( elem.length > 0 ) {
                        if( avail.weekends == 0 ) { // exclude weekends
                            if( !elem[0].classList.contains('fc-day-sat') && !elem[0].classList.contains('fc-day-sun') ) {
                                elem[0].classList.add('bg-green-400')
                            }
                        } else {
                            elem[0].classList.add('bg-green-400')
                        }
                    }
                }
            }
        }
    }

    const acceptOption = async () => {
        const data = { start: startDate, end: endDate };
        const response = await fetch('https://api.planileo.fr/planning/acceptOption/' + currEvent.data.id, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                getOptions()
                setEventModal(false)
            } else {
                alert(result.message)
            }
        }
    }

    const refuseOption = async () => {
        const data = { start: startDate, end: endDate };
        const response = await fetch('https://api.planileo.fr/planning/refuseOption/' + currEvent.data.id, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                getOptions()
                setEventModal(false)
            } else {
                alert(result.message)
            }
        }
    }

    const deleteUnavail = async () => {
        const response = await fetch('https://api.planileo.fr/planning/deleteUnavail/' + currEvent.id, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                getUnavails()
                setEventModal(false)
            } else {
                alert(result.message)
            }
        }
    }


    return (
        <Layout>
            <FilAriane firstItem={{ label: "Mon Planning", url: "/form/mon-planning" }} />
            <div className="mb-4 block md:flex md:items-center md:justify-between">
                <div className="mb-4 md:mb-0">
                    <h3 className="text-xl font-semibold">Mon planning</h3>
                    <p className="font-small text-gray-600">Les organismes autorisés peuvent visualiser votre planning et poser des options dessus.</p>
                </div>
                <div className="text-right">
                    <p className="mb-2 text-sm text-gray-600 underline cursor-pointer" onClick={() => setListAvailModal(true)}>Mes disponibilités</p>
                    <div className="flex">
                        <Button size="sm" gradientDuoTone="pinkToOrange" onClick={() => setUnavailModal(true)}><HiOutlinePlus className="mr-1 h-5 w-5" /> Indisponibilité</Button>
                        <Button className="ml-4" size="sm" gradientDuoTone="greenToBlue" onClick={() => setAvailModal(true)}><HiOutlinePlus className="mr-1 h-5 w-5" /> Disponibilité</Button>
                    </div>
                </div>
            </div>
            <div className='mb-6'>
                <FullCalendar
                    plugins={[daygridPlugin, timeGridPlugin]}
                    editable
                    selectable
                    allDaySlot={false}
                    headerToolbar={{
                        start: "today prev next",
                        end: "dayGridMonth timeGridWeek dayGridDay",
                    }}
                    events={options.concat(unavails)}
                    slotMinTime={'06:00:00'}
                    slotMaxTime={'20:00:00'}
                    height={'auto'}
                    locale={frLocale}
                    dayCellDidMount={dayCellDidMount}
                    views={["dayGridMonth", "timeGridWeek", "dayGridDay"]}
                    eventClick={eventClick}
                    initialView={calendarInitial}
                />
            </div>
            <AvailModal closeModal={closeAvailModal} modalState={availModal} />
            <ListAvailModal closeModal={closeListAvailModal} avails={avails} modalState={listAvailModal} />
            <Modal show={unavailModal} onClose={() => setUnavailModal(false)}>
                <Modal.Header>Ajouter une indisponibilité</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <div>
                            <p className="font-small text-gray-600">Les organismes ne pourront pas poser d'option sur ce créneau</p>
                        </div>
                        <form onSubmit={addUnavail}>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="fullDay" value="Journée complète" />
                                </div>
                                <input type="date" className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 p-2.5 text-sm rounded-lg"
                                    id="fullDay"
                                    onChange={(e) => setFullDayDate(e.target.value)}
                                />
                            </div>
                            <hr className="h-px mt-6 mb-2 bg-gray-200 border-0"></hr>
                                <p className="w-full text-center text-gray-600">ou</p>
                            <hr className="h-px mt-2 mb-6 bg-gray-200 border-0"></hr>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="startDate" value="Date de début" />
                                </div>
                                <input type="datetime-local" min={todayMidnight} defaultValue={todayMidnight} className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 p-2.5 text-sm rounded-lg"
                                    id="startDate"
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className="mt-2">
                                <div className="mb-2 block">
                                    <Label htmlFor="endDate" value="Date de fin" />
                                </div>
                                <input type="datetime-local" min={todayMidnight} defaultValue={todayMidnight} className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 p-2.5 text-sm rounded-lg"
                                    id="endDate"
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                            <div className="w-full mt-4">
                                <Button type="submit">Ajouter</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal dismissible show={eventModal} onClose={() => setEventModal(false)}>
                <Modal.Header>{currEvent.title}</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <div>
                            {currEvent.type === "option" &&
                                <div>
                                    {currEvent.status === "valid_form" && 
                                        <><Alert color="warning" rounded>L'entreprise <span className="font-medium">{currEvent.data.entreprise}</span> souhaite organiser une formation sur ce créneau.</Alert>
                                        {currEvent.data.commentaire && <p className="font-small text-gray-600 mt-4">Commentaire laissé par l'entreprise : {currEvent.data.commentaire}</p>}</>
                                    }
                                    {currEvent.status === "valid_org" && 
                                        <><Alert color="warning" rounded>Vous avez validé cette option le {parseSqlDate(currEvent.data.valid_form_date)}<br/>En attente de la validation finale de l'organisme de formation <span className="font-medium">{currEvent.data.entreprise}</span></Alert>
                                        {currEvent.data.commentaire && <p className="font-small text-gray-600 mt-4">Commentaire laissé par l'entreprise : {currEvent.data.commentaire}</p>}</>
                                    }
                                    {currEvent.status === "confirmed" && 
                                        <>
                                            <Alert color="success" rounded>Une formation avec l'entreprise <span className="font-medium">{currEvent.data.entreprise}</span> est prévue sur ce créneau.</Alert>
                                            <Timeline className="mt-4">
                                                <Timeline.Item>
                                                    <Timeline.Point />
                                                    <Timeline.Content>
                                                        <Timeline.Time>{parseSqlDate(currEvent.data.creation_date)}</Timeline.Time>
                                                        <Timeline.Title>Option posée</Timeline.Title>
                                                        <Timeline.Body>
                                                            Par l'organisme de formation
                                                        </Timeline.Body>
                                                    </Timeline.Content>
                                                </Timeline.Item>
                                                <Timeline.Item>
                                                    <Timeline.Point />
                                                    <Timeline.Content>
                                                        <Timeline.Time>{parseSqlDate(currEvent.data.valid_form_date)}</Timeline.Time>
                                                        <Timeline.Title>Option validée côté formateur</Timeline.Title>
                                                    </Timeline.Content>
                                                </Timeline.Item>
                                                <Timeline.Item>
                                                    <Timeline.Point />
                                                    <Timeline.Content>
                                                        <Timeline.Time>{parseSqlDate(currEvent.data.valid_org_date)}</Timeline.Time>
                                                        <Timeline.Title>Validation finale</Timeline.Title>
                                                        <Timeline.Body>
                                                            Par l'organisme de formation
                                                        </Timeline.Body>
                                                    </Timeline.Content>
                                                </Timeline.Item>
                                            </Timeline>
                                            {currEvent.data.commentaire && <p className="font-small text-gray-600 mt-4">Commentaire laissé par l'entreprise : {currEvent.data.commentaire}</p>}
                                        </>
                                    }
                                </div>
                            }
                            {currEvent.type === "Indisponibilité" && <p className="font-small text-gray-600">Vous n'êtes pas disponible sur ce créneau ce créneau.</p>}
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {currEvent.type === "option" && (
                        <>
                            {currEvent.status === "valid_form" ? (
                                <>
                                    <Button onClick={() => { setEventModal(false); acceptOption() }}>Valider la demande</Button>
                                    <Button color="gray" onClick={() => { setEventModal(false); refuseOption() }}>
                                        Refuser
                                    </Button>
                                </>
                            ) : (
                                <Button onClick={() => setEventModal(false)}>
                                    Fermer
                                </Button>
                            )}
                        </>
                    )}
                    {currEvent.type === "Indisponibilité" && (
                        <Button color="failure" onClick={() => deleteUnavail()}>
                            Supprimer l'indisponibilité
                        </Button>
                    )}

                </Modal.Footer>
            </Modal>
        </Layout>
    );
}
export default FormPlanning;