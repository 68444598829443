import React, { useEffect, useState } from 'react';
import { Button, Navbar, Avatar, Dropdown } from "flowbite-react";
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'


function Menu() {
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies(['userInfo']);
    const [user, setUser] = useState([]);

    useEffect(() => {
        if( cookies.userInfo ) { getUserInfo() }
    }, []);

    const getUserInfo = async () => {
        const response = await fetch('https://api.planileo.fr/users/getUserInfo/' + cookies.userInfo.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const res = await response.json()
            setUser(res)
        }
    };

    let logout = async (e) => {
        let res = await fetch("https://api.planileo.fr/logout", {
            method: "POST",
            credentials: 'include'
        });
        if (res.status === 200) {
            removeCookie('userInfo',{path:'/'});
        }
    }

    return (
        <Navbar fluid rounded>
            <Navbar.Brand>
                <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">Planileo</span>
            </Navbar.Brand>
            <div className="flex md:order-2">
                {cookies.userInfo ? (
                    <Dropdown
                        arrowIcon={false}
                        inline
                        label={ <Avatar alt="User settings" rounded /> }>
                        <Dropdown.Header>
                            {user.role === "Formateur" && <span className="block text-sm">{user.prenom} {user.nom}</span>}
                            {user.role === "Organisme de formation" && <span className="block text-sm">{user.entreprise}</span>}
                            <span className="block truncate text-sm font-medium">{user.email}</span>
                        </Dropdown.Header>
                        <Dropdown.Item>
                            {user.role === "Formateur" ? (
                                <a href="/form/mon-compte">Mon compte</a>
                            ) : (
                                <a href="/org/mon-compte">Mon compte</a>
                            )}
                        </Dropdown.Item>
                        <Dropdown.Item>
                            {user.role === "Formateur" ? (
                                <a href="/form/mon-planning">Mon planning</a>
                            ) : (
                                <a href="/org/tableau-de-bord">Tableau de bord</a>
                            )}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={logout}>Déconnexion</Dropdown.Item>
                    </Dropdown>
                ) : (
                    <>
                        <a href="/login" className="mr-1 rounded-lg px-4 py-3 text-sm font-medium text-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-800 md:mr-2 md:px-5 md:py-2.5">Connexion</a>
                        <Button href="/register">Inscription</Button>
                    </>
                )}
            </div>
        </Navbar>
    );
}
export default Menu;