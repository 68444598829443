import { useCookies } from 'react-cookie'


export function parseSqlDate( date ) {
    const dateArr = date.split(/[- : T]/);
    return dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0] + ' à ' + dateArr[3] + ':' + dateArr[4]
}

export function parseSqlDateNoHour( date ) {
    const dateArr = date.split(/[- : T]/);
    return dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0]
}

export function getDaysArray (start, end) {
    const daylist = [];
    var dateEnd = new Date(end)
    dateEnd.setDate(dateEnd.getDate() + 1) // add one day to date end (prevent UTC)

    for(var dt=new Date(start); dt<= dateEnd; dt.setDate(dt.getDate()+1)){
        const offset = dt.getTimezoneOffset()
        dt = new Date(dt.getTime() - (offset*60*1000))
        daylist.push(dt.toISOString().split('T')[0]);
    }
    return daylist;
}

var d = new Date()
d.setHours(0,0,0,0)
var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
var localISOTime = (new Date(d - tzoffset)).toISOString().slice(0, -8);

export const todayMidnight = localISOTime