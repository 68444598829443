import React, { useState, useEffect } from "react";
import { Button, Label, TextInput, Alert, Checkbox } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';


const LoginForm = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate()
    const [cookies, setCookie] = useCookies();

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let res = await fetch("https://api.planileo.fr/users/login", {
                method: "POST",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                if (resJson.success == true) {
                    setIsError(false);
                    setCookie('userInfo', resJson.userInfo, {maxAge: 3600, path:'/'});
                    if (resJson.userInfo.roleId === 1) {
                        navigate('/admin/dashboard', {replace: true})
                    } else if(resJson.userInfo.roleId === 2) {
                        navigate('/org/tableau-de-bord', {replace: true})
                    } else {
                        navigate('/form/mon-planning', {replace: true})
                    }
                } else {
                    setIsError(true);
                }
            } else {
                setIsError(true);
            }
        } catch (err) {
            alert("Une erreur est survenue")
            console.log(err);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="flex mx-auto px-4 w-full max-w-lg flex-col gap-4">
            <Alert className={`${!isError && "hidden"}`} color="failure" icon={HiInformationCircle}>
                <span className="font-medium">Erreur</span> Vérifiez vos informations.
            </Alert>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="email" value="Votre email" />
                </div>
                <TextInput id="email" type="email" onChange={(e) => setEmail(e.target.value)} placeholder="nom@gmail.com" required />
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="password" value="Votre mot de passe" />
                </div>
                <TextInput id="password" onChange={(e) => setPassword(e.target.value)} type="password" autoComplete="on" required />
            </div>
            <div className="flex justify-between">
                <div className="flex items-center gap-2">
                    <Checkbox id="remember" />
                    <Label htmlFor="remember">Se souvenir de moi</Label>
                </div>
                <a href="#" className="text-sm text-cyan-700 hover:underline dark:text-cyan-500">
                    Mot de passe oublié
                </a>
            </div>
            <div className="flex items-center gap-2">
                <p className="text-sm">Pas encore de compte ? <a className="underline" href="/register">Inscription</a></p>
            </div>
            <Button type="submit">Connexion</Button>
        </form>
    );
}
export default LoginForm;