import React, { useState, useEffect } from "react";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import { HiMenu } from "react-icons/hi";


const Header = ({openSidebar}) => {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['userInfo']);
    const [user, setUser] = useState([]);

    useEffect(() => {
        getUserInfo();
    }, []);

    const getUserInfo = async () => {
        const response = await fetch('https://api.planileo.fr/users/getUserInfo/' + cookies.userInfo.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const res = await response.json()
            setUser(res)
        }
    };

    let logout = async (e) => {
        let res = await fetch("https://api.planileo.fr/logout", {
            method: "POST",
            credentials: 'include'
        });
        if (res.status === 200) {
            //removeCookie('userInfo', {domain:'.planileo.fr', path:'/'});
            removeCookie('userInfo', {path:'/'});
            navigate('/', { replace: true })
        } else {
            console.log(res)
        }
    }
    return (
        <>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <Navbar fluid rounded>
            <div className="flex items-center">
                <div className="lg:hidden block mr-2 cursor-pointer" onClick={openSidebar}>
                    <HiMenu size={22} />
                </div>
                <Navbar.Brand>
                    <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">Planileo</span>
                </Navbar.Brand>
            </div>
            <div className="flex md:order-2">
                <Dropdown
                    arrowIcon={false}
                    inline
                    label={
                        <Avatar alt="User settings" rounded />
                    }
                >
                    <Dropdown.Header>
                        {user.role === "Formateur" && <span className="block text-sm">{ user.prenom } { user.nom }</span> }
                        {user.role === "Organisme de formation" && <span className="block text-sm">{ user.entreprise }</span> }
                        <span className="block truncate text-sm font-medium">{ user.email }</span>
                    </Dropdown.Header>
                    <Dropdown.Item>
                        { user.role === "Formateur" ? (
                            <a href="/form/mon-compte">Mon compte</a>
                        ) : (
                            <a href="/org/mon-compte">Mon compte</a>
                        )}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={logout}>Déconnexion</Dropdown.Item>
                </Dropdown>
            </div>
        </Navbar>
        </>
    );
}
export default Header;