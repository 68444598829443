import React, { useEffect, useState } from 'react';
import Layout from "./global/Layout";


function AdminDashboard() {

    return (
        <Layout>
            <div>
                admin dashboard
            </div>
        </Layout>
    );
}
export default AdminDashboard;