import React, { useEffect, useState } from 'react';
import Layout from "./global/Layout";
import { useNavigate } from 'react-router-dom'
import { Table } from "flowbite-react";


function AdminOrganismes() {
    let [organismes, setOrganismes] = useState(false);
    const navigate = useNavigate();

    const getOrganismes = async () => {
        try {
            const response = await fetch('https://api.planileo.fr/admin/getOrganismes', {
                method: 'GET',
                credentials: 'include'
            });
            if( response.status === 401 ) {
                console.log('unauthorized')
                navigate('/', {replace: true})
            } else if( response.status !== 200 ) {
                console.log('error');
            } else {
                const result = await response.json();
                console.log(result.data)
                setOrganismes(result.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getOrganismes();
    }, []);


    return (
        <Layout>
            <h3 className="text-xl font-semibold">Liste des organismes</h3>
            <div className="mt-4 overflow-x-auto">
                <Table>
                    <Table.Head>
                        <Table.HeadCell>Nom</Table.HeadCell>
                        <Table.HeadCell>Prénom</Table.HeadCell>
                        <Table.HeadCell>Email</Table.HeadCell>
                        <Table.HeadCell>Price</Table.HeadCell>
                        <Table.HeadCell>
                            <span className="sr-only">Edit</span>
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {organismes && organismes.length > 0 && organismes.map((organisme, index) => (
                            <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {organisme.email}
                                </Table.Cell>
                                <Table.Cell>{organisme.email}</Table.Cell>
                                <Table.Cell>{organisme.email}</Table.Cell>
                                <Table.Cell>{organisme.email}</Table.Cell>
                                <Table.Cell>
                                    <a href="#" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500">
                                        Edit
                                    </a>
                                </Table.Cell>
                            </Table.Row>
                        
                        ))} 
                    </Table.Body>
                </Table>
                {organismes.length === 0 && 
                        <p className="font-small text-gray-600 ml-4 mt-2">Pas d'organismes pour l'instant</p>
                    }
            </div>
        </Layout>
    );
}
export default AdminOrganismes;